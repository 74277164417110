import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { StatusNotification } from "@/constants";

export default function useToastNotification(option = { timeout: 3000 }) {
    const toast = useToast();
    const dispatchNotification = (title, type, text) => {
        let icon = '';
        switch (type) {
            case StatusNotification.SUCCESS:
                icon = "CheckIcon";
                break;
            case StatusNotification.WARNING:
                icon = "AlertTriangleIcon";
                break;
            case StatusNotification.INFO:
                icon = "InfoIcon";
                break;
            case StatusNotification.ERROR:
                icon = "XCircleIcon";
                break;
            default:
                icon = "InfoIcon";
                break;
        }
        toast({
            component: ToastificationContent,
            props: {
                title: title,
                icon: icon,
                text: text,
                variant: type
            }}, option)
    }
    return {
        dispatchNotification
    }
}