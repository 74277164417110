import store from "@/store";

export default function useHandlerSidebar() {
  const enableSidebarCreate = (val) => {
    store.commit('formSidebar/UPDATE_COMPONENTSIDEBAR_CREATE', val)
  }
  const enableSidebarUpdate = (val) => {
    store.commit('formSidebar/UPDATE_COMPONENTSIDEBAR_EDIT', val);
  };
  return {
    enableSidebarCreate,
    enableSidebarUpdate,
  };
}
