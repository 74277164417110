<template>
  <b-container>
    <b-alert v-if="error.length > 0" variant="danger" show>
      <h4 class="alert-heading">Error obteniendo datos</h4>
      <div class="alert-body">
        {{ error }}
      </div>
    </b-alert>
    <b-card :title="title">
      <!-- searchbar -->
      <!-- <div class="custom-search d-flex justify-content-end">
        <b-form-group>
          <div class="d-flex align-items-center">
            <label class="mr-1">Buscar</label>
            <b-form-input
              type="text"
              class="d-inline-block"
              @input="onSearch"
              placeholder="Buscar"
            />
          </div>
        </b-form-group>
      </div> -->
      <template #header>
        <b-button pill variant="success" @click="enableSidebar">{{
          titleCreateButton
        }}</b-button>
      </template>
      <slot name="body"></slot>
    </b-card>
    <slot name="sidebar"></slot>
  </b-container>
</template>
<script>
import useContainer from "./useContainer";
import {
  BBadge,
  BSpinner,
  BTable,
  BAlert,
  BContainer,
  BFormSelect,
  BFormGroup,
  BCard,
  BCardText,
  BCardGroup,
  BCardBody,
  BFormInput,
  BInputGroup,
  BForm,
  BButton,
  BFormCheckbox,
  BRow,
  BCol,
} from "bootstrap-vue";
export default {
  components: {
    BBadge,
    BSpinner,
    BTable,
    BAlert,
    BContainer,
    BFormSelect,
    BFormGroup,
    BCard,
    BCardText,
    BCardGroup,
    BCardBody,
    BFormInput,
    BInputGroup,
    BForm,
    BButton,
    BFormCheckbox,
    BRow,
    BCol,
  },
  props: {
    error: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    titleCreateButton: {
      type: String,
      required: true,
    },
  },
  setup() {
    const { onSearch, enableSidebar } = useContainer();
    return {
      onSearch,
      enableSidebar,
    };
  },
};
</script>