import { inject } from "@vue/composition-api";
import store from "@/store";

export default function useContainer() {
  const onSearch = inject("onSearch");
  const enableSidebar = () => {
    store.commit('formSidebar/UPDATE_COMPONENTSIDEBAR_CREATE', true)
  }
  return {
    onSearch,
    enableSidebar,
  };
}
