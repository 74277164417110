var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-container', [_vm.error.length > 0 ? _c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": ""
    }
  }, [_c('h4', {
    staticClass: "alert-heading"
  }, [_vm._v("Error obteniendo datos")]), _c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")])]) : _vm._e(), _c('b-card', {
    attrs: {
      "title": _vm.title
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('b-button', {
          attrs: {
            "pill": "",
            "variant": "success"
          },
          on: {
            "click": _vm.enableSidebar
          }
        }, [_vm._v(_vm._s(_vm.titleCreateButton))])];
      },
      proxy: true
    }])
  }, [_vm._t("body")], 2), _vm._t("sidebar")], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }