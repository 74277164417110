export default {
    product: {
        bulkLoadProducts: "product/bulkLoadProducts"
    },
    subscription: {
        application: {
            getAll: "subscription/getAllApplications",
            storeApplication: "subscription/storeApplication",
            updateApplication: "subscription/updateApplication",
        },
        usergrouptypeplan: {
            getAll: "subscription/getAllApplications",
        },
        offer: {
            getAll: "subscription/getAllOffers",
            storeOffer: "subscription/storeOffer",
        },
        option: {
            getAll: "subscription/getAllOptions",
            storeOption: "subscription/storeOption",
            updateOption: "subscription/updateOption",
        },
        plan: {
            getPlansByApplication: "subscription/getPlansByApplication",
            getAll: "subscription/getAllPlans",
            storePlan: "subscription/storePlan",
            updatePlan: "subscription/updatePlan",
        },
        product: {
            getProductsByApplication: "subscription/getProductsByApplication",
            storeProducts: "subscription/storeProductsByApplication",
            deleteProductsByApplication: "subscription/deleteProductsByApplication",
        }
    }
}