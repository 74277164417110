<template>
  <b-sidebar
    sidebar-class="sidebar-lg"
    :visible="$store.state['formSidebar'].componentSidebar"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
    @change="changeSidebar"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        "
      >
        <h5 class="mb-0">{{ formTitle }}</h5>
        <div>
          <feather-icon
            class="ml-1 cursor-pointer"
            icon="XIcon"
            size="16"
            @click="hide"
          />
        </div>
      </div>
      <slot name="body"></slot>
    </template>
  </b-sidebar>
</template>
<script>
import store from "@/store";
import {
  BBadge,
  BSpinner,
  BTable,
  BAlert,
  BContainer,
  BFormSelect,
  BFormGroup,
  BCard,
  BCardText,
  BCardGroup,
  BCardBody,
  BFormInput,
  BInputGroup,
  BForm,
  BButton,
  BFormCheckbox,
  BSidebar,
  BRow,
  BCol,
} from "bootstrap-vue";
export default {
  components: {
    BBadge,
    BSpinner,
    BTable,
    BAlert,
    BContainer,
    BFormSelect,
    BFormGroup,
    BCard,
    BCardText,
    BCardGroup,
    BCardBody,
    BFormInput,
    BInputGroup,
    BForm,
    BButton,
    BFormCheckbox,
    BSidebar,
    BRow,
    BCol,
  },
  props: {
    formTitle: {
      type: String,
      required: true,
    },
  },
  setup() {
    const changeSidebar = (val) => {
      store.commit('formSidebar/UPDATE_COMPONENTSIDEBAR', val)
    }
    return {
      changeSidebar,
    };
  },
};
</script>