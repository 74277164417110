var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-sidebar', {
    attrs: {
      "sidebar-class": "sidebar-lg",
      "visible": _vm.$store.state['formSidebar'].componentSidebar,
      "bg-variant": "white",
      "shadow": "",
      "backdrop": "",
      "no-header": "",
      "right": ""
    },
    on: {
      "change": _vm.changeSidebar
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var hide = _ref.hide;
        return [_c('div', {
          staticClass: "\n        d-flex\n        justify-content-between\n        align-items-center\n        content-sidebar-header\n        px-2\n        py-1\n      "
        }, [_c('h5', {
          staticClass: "mb-0"
        }, [_vm._v(_vm._s(_vm.formTitle))]), _c('div', [_c('feather-icon', {
          staticClass: "ml-1 cursor-pointer",
          attrs: {
            "icon": "XIcon",
            "size": "16"
          },
          on: {
            "click": hide
          }
        })], 1)]), _vm._t("body")];
      }
    }], null, true)
  });
}
var staticRenderFns = []

export { render, staticRenderFns }